export const GET_CATEGORIES = 'GET_CATEGORIES';

export const GET_TIME_SLOTS = 'GET_TIME_SLOTS';

export const ADD_TO_CART = "ADD_TO_CART";

export const GET_CART_ITEMS = "GET_CART_ITEMS";

export const CART_ACTIONS = "CART_ACTIONS";

export const UPDATE_ITEM_CART = "UPDATE_ITEM_CART";

export const DELETE_ITEM_CART = "DELETE_ITEM_CART";

export const GET_ITEM = "GET_ITEM";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";

export const SUBMIT_ERROR = "SUBMIT_ERROR";