import './App.css';
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About';
import Menu from './components/Menu';
import Contact from './components/Contact';
import Checkout from './components/Checkout/Checkout';
import { Toaster } from './Shared/components';
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import ar from "./lang/ar.json"
import en from "./lang/en.json"

const saveLanguageToLocalStorage = (language) => {
  localStorage.setItem("i18nextLang", language);
}
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { global: en },
      ar: { global: ar }
    },
    lng: localStorage.getItem("i18nextLang") || "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  }).then(() => {
    i18n.on('languageChanged', (lang) => {
      saveLanguageToLocalStorage(lang);
    })
  });

function App() {
  const location = useLocation();
  const [t, i18n] = useTranslation('global');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.documentElement.dir = i18n.dir();
  }, [i18n.language]);
  return (
    <>
      <Toaster />
      <div className="container-xxl bg-white p-0">
        {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home translate={t} i18n={i18n}/>} />
          <Route path="/about" element={<About translate={t} i18n={i18n}/>} />
          <Route path='/menu' element={<Menu translate={t} i18n={i18n}/>} />
          <Route path='/contact' element={<Contact translate={t} i18n={i18n}/>} />
          <Route path='/checkout' element={<Checkout translate={t} i18n={i18n}/>} />
        </Routes>
      </div>
    </>
  );
}

export default App;
