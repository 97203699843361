import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = () => (
    <ToastContainer
        position={"bottom-right"}
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnVisibilityChange
        pauseOnHover
    />
);

export default Toaster;
export { toast };
