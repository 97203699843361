import React from 'react'
import { Link } from 'react-router-dom'

function EmptyCart() {
    return (
        <div className="container-xxl position-relative p-0">
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-12">
                        <h2>There is no item in your cart</h2>
                        <Link to="/menu" className='btn btn-main fw-bold text-white'>Back to menu</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyCart