import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useShoppingCart } from '../shopping-cart-context';
import TopBar from './TopBar';

const Navbar = ({ translate, i18n, dispatch }) => {
  const location = useLocation();
  const { cartQuantity } = useShoppingCart();
  return (
    <>
      <TopBar i18n={i18n} dispatch={dispatch}/>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
        <div className="nav-links w-100 pt-4 navbar-nav d-lg-flex d-none justify-content-between">
          <div className="right d-flex align-items-center">
            <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>{translate('Home')}</Link>
            <Link to="/about" className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}>{translate('About')}</Link>
          </div>
          <Link to="/" className="navbar-brand p-0">
            <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="Logo" />
          </Link>
          <div className="left d-flex align-items-center">
            <Link to="/menu" className={`nav-item nav-link ${location.pathname === '/menu' ? 'active' : ''}`}>{translate('Menu')}</Link>
            <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>{translate('Contact')}</Link>
            <Link to="/checkout" className={`nav-item nav-link ${location.pathname === '/cart' ? 'active' : ''}`}>
              <span className="position-relative">
                <i className="fas fa-shopping-cart text-white fa-lg"></i>
                <span className={`cart-count-label position-absolute top-0 ${i18n.language == 'ar' ? 'right-0' : 'left-0'} translate-middle badge rounded-pill bg-primary text-white`}>
                  {cartQuantity > 0 ? cartQuantity : 0}
                </span>
              </span>
            </Link>
          </div>
        </div>
        <div className="mobile d-flex justify-content-between align-items-center w-100 d-lg-none d-block">
          <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="fa fa-bars"></span>
          </button>
          <Link to="/" className="navbar-brand d-lg-none d-block p-0">
            <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="Logo" />
          </Link>
          <Link to="/checkout" className="px-2">
            <span className="position-relative">
              <i className="fas fa-shopping-cart text-white fa-lg"></i>
              <span className={`cart-count-label position-absolute top-0 ${i18n.language == 'ar' ? 'right-0' : 'left-0'} translate-middle badge rounded-pill bg-primary text-white`}>
                {cartQuantity > 0 ? cartQuantity : 0}
              </span>
            </span>
          </Link>
        </div>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0 pe-4 d-lg-none d-block">
            <Link to="/" className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}>{translate('Home')}</Link>
            <Link to="/about" className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}>{translate('About')}</Link>
            <Link to="/menu" className={`nav-item nav-link ${location.pathname === '/menu' ? 'active' : ''}`}>{translate('Menu')}</Link>
            <Link to="/contact" className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>{translate('Contact')}</Link>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar