import React from 'react'
import { useShoppingCart } from '../../../Shared/shopping-cart-context'
import Item from './Item'

const Items = ({translate}) => {
    const { cartItems } = useShoppingCart();
    return (
        <div className="card p-lg-2 rounded-3">
            <div className="card-body p-lg-3">
                <h5 className="card-title">{translate('Order details')}</h5>
                <div className="items mt-4">
                    {cartItems.map((item, index) =>
                        <Item key={index} item={item} translate={translate}/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Items