import React, { useEffect, useReducer } from 'react'
import reducer, { initialState } from "../Shared/reducer";
import {
  Navbar,
  MenuSection,
  Footer,
  AddItem
} from '../Shared/components'
import { GET_CART_ITEMS, GET_CATEGORIES } from "../Shared/actions";
import ShoppingCartProvider from '../Shared/shopping-cart-context'

const Menu = ({ translate, i18n }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: GET_CATEGORIES, payload: dispatch })
    dispatch({ type: GET_CART_ITEMS, payload: dispatch })
  }, [])

  return (
    <>
      <ShoppingCartProvider state={state} dispatch={dispatch}>
        <div className="container-xxl position-relative p-0">
          <Navbar translate={translate} i18n={i18n} dispatch={dispatch} />
          <div className="container-xxl py-5 bg-dark hero-header mb-5">
            <div className="container text-center my-5 pt-5 pb-4">
              <h1 className="display-3 text-white mb-3 animated slideInDown">{translate('Food Menu')}</h1>
            </div>
          </div>
        </div>
        <MenuSection state={state} dispatch={dispatch} translate={translate} />
        <AddItem item={state.item} translate={translate} />
        <Footer translate={translate} i18n={i18n} />
      </ShoppingCartProvider>
    </>
  )
}

export default Menu