import React, { useEffect, useState } from 'react'
import { useShoppingCart } from '../shopping-cart-context'
import { APP_URL, CURRENCY } from '../global';

const AddItem = ({ item, translate }) => {
    const { addToCart } = useShoppingCart();
    const [qty, setQty] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState({});
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [itemPrice, setItemPrice] = useState(item.price);

    useEffect(() => {
        setQty(1)
        setItemPrice(item.price)
        setSelectedVariant({})
        setSelectedExtras([])
    }, [item.id, item.price])

    useEffect(() => {
        if (selectedExtras.length > 0) {
            const totalPrice = selectedVariant.price + selectedExtras.reduce((sum, extra) => sum + extra.price, 0);
            setItemPrice(totalPrice);
        } else if (selectedVariant && selectedVariant.price) {
            setItemPrice(selectedVariant.price);
        } else {
            setItemPrice(item.price);
        }
    }, [selectedExtras])

    const handleVariantChange = (e) => {
        if (!e.target.value) return;
        const selectedVariantId = parseInt(e.target.value);

        if (item.variants && item.variants.length > 0) {
            const selectedVariant = item.variants.find(variant => variant.id === selectedVariantId);
            setSelectedVariant(selectedVariant);
            if (selectedVariant) {
                setItemPrice(selectedVariant.price);
            }
        }
    };
    const handleExtraChange = (e) => {
        const extraId = parseInt(e.target.value);
        const isChecked = e.target.checked;

        setSelectedExtras((prevExtras) => {
            const currentExtras = Array.isArray(prevExtras) ? prevExtras : [];

            if (isChecked) {
                const getExtra = selectedVariant.extras.find((extra) => extra.id === extraId);
                return [...currentExtras, getExtra];
            } else {
                return currentExtras.filter((extra) => extra.id !== extraId);
            }
        });
    };

    const parseOptions = (optionsString) => {
        try {
            return JSON.parse(optionsString);
        } catch (error) {
            console.error('Error parsing options:', error);
            return {};
        }
    };
    const hasVariants = item.variants && item.variants.length > 0;
    const isVariantSelected = Object.keys(selectedVariant).length > 0;

    const shouldShowTotalPrice = !hasVariants || (hasVariants && isVariantSelected);
    return (
        <div className="modal fade" id="add-item" tabIndex="-1" aria-labelledby="add-item-Label" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body position-relative px-0 pt-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="card border-0 w-100">
                            <img src={APP_URL + item.logom} className="card-img-top" alt="item" style={{ height: '350px', objectFit: 'cover' }} />
                            <div className="card-body">
                                <h5 className="card-title">{item.name}</h5>
                                <p className="card-text text-gray mb-lg-4">{item.description}</p>
                                <div className="total">
                                    {
                                        item.variants && item.variants.length > 0
                                            ?
                                            <div className="variant-select mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="variant" className="form-label">{translate('Select')}</label>
                                                    <select
                                                        id="variant"
                                                        name="variant"
                                                        className="form-select"
                                                        value={selectedVariant.id}
                                                        onChange={(e) => handleVariantChange(e)}
                                                    >
                                                        <option value="">Select an option</option>
                                                        {item.variants.map((variant) => {
                                                            const options = parseOptions(variant.options);
                                                            const variantName = options[Object.keys(options)[0]];
                                                            return (
                                                                <option key={variant.id} value={variant.id}>
                                                                    {variantName}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {
                                                    selectedVariant && selectedVariant.extras && selectedVariant.extras.length > 0
                                                        ?
                                                        <div className="form-group mt-3">
                                                            <label htmlFor="variant" className="form-label">Extras</label>
                                                            {
                                                                selectedVariant.extras.map((extra) => {
                                                                    return (
                                                                        <div className="custom-control custom-checkbox mb-3" key={extra.id}>
                                                                            <label className="custom-control-label d-flex" htmlFor={`extra_${extra.id}`}>
                                                                                <input className="custom-control-input" id={`extra_${extra.id}`} name="extra" value={extra.id} type="checkbox"
                                                                                    onChange={handleExtraChange}
                                                                                    data-price={extra.price}
                                                                                />
                                                                                <span className="mx-2">{extra.name}</span>
                                                                                <span className="mx-2">{extra.price.toLocaleString()} {translate(CURRENCY)}</span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    <div className="d-flex justify-content-between align-items-center">
                                        {
                                            shouldShowTotalPrice && (
                                                <>
                                                    <div className="qty">
                                                        <div className="quantity-input border-1">
                                                            <button type="button" className="btn quantity-btn"
                                                                onClick={() => {
                                                                    setQty(qty + 1)
                                                                }}>
                                                                <i className="fa fa-plus"></i>
                                                            </button>
                                                            <span className="mx-3">{qty}</span>
                                                            <button type="button" className="btn quantity-btn"
                                                                disabled={qty <= 1 ? true : false}
                                                                onClick={() => {
                                                                    setQty(qty - 1)
                                                                }}>
                                                                <i className="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="total-price d-flex align-items-center">
                                                        <strong className="text-primary fs-5">
                                                            <span className="net-price">{(itemPrice * qty).toLocaleString()} </span> {translate(CURRENCY)}
                                                        </strong>
                                                        {
                                                            item.discounted_price ? <strong className="text-muted ms-2" style={{ textDecoration: 'line-through' }}><span className="discount">{item.discounted_price.toLocaleString()} {translate(CURRENCY)}</span></strong> : null
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-main fw-bold w-100 py-3 add-item-to-cart" data-bs-dismiss="modal"
                            onClick={() => addToCart(item, qty, selectedVariant, selectedExtras)}
                            disabled={item.variants && item.variants.length > 0 && Object.keys(selectedVariant).length === 0 }
                        >
                            {translate('Add')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddItem