import React from 'react'
import { VENDOR_NAME } from '../global'

const AboutSection = ({ translate }) => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="row g-5 justify-content-center align-items-center">
                    <div className="col-xl-4 col-lg-6 col-md-12 text-center">
                        <img src={process.env.PUBLIC_URL + '/img/logo.png'} alt="Logo" className='img-about' />
                        <h5 className="section-title ff-secondary text-primary fw-normal">{translate('About Us')}</h5>
                        <h1 className="mb-4">{translate('Welcome to')} {VENDOR_NAME}</h1>
                        <p className="mb-4">
                            {translate('about_paragraph')}
                        </p>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 about-video text-center">
                        <video src={process.env.PUBLIC_URL + "/img/about.mp4"} className="about-iframe" controls="controls" autoPlay loop muted>
                            <source src={process.env.PUBLIC_URL + "/img/about.mp4"} type="video/mp4" />
                        </video>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 text-center">
                        <h1 className="mb-4">{translate('Enjoy Our Delicious Meal')}</h1>
                        <p className="mb-4">
                            {translate('Indulge in the exquisite flavors of our carefully crafted meals. Immerse yourself in a culinary journey where each bite is a symphony of taste and texture')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection