import React from 'react'
import { UPDATE_USER_INFO } from '../../../Shared/actions';


const UserInformation = ({ state, dispatch, loading, translate, i18n }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        dispatch({ type: UPDATE_USER_INFO, payload: { [name]: value } });
    };

    return (
        <div className="checkout-content">
            <div className="card p-lg-3 rounded-3">
                <div className="card-body">
                    <h5 className="card-title">{translate('Billing Details')}</h5>

                    <div className="row">
                        <div className="col-lg-12 mb-3">
                            <label htmlFor="phone-number" className="form-label">{translate('Phone Number')} <span className="text-danger">*</span></label>
                            <input type="text" className="form-control rounded-2" id="phone-number" name="phoneNumber"
                                value={state.userInformation.phoneNumber || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        {/* <div className="col-lg-6 mb-3">
                            <label htmlFor="comments" className="form-label">{translate('Pickup time')} <span className="text-danger">*</span></label>
                            <select className="form-select rounded-2" name="timeSlots"
                                onChange={handleInputChange}
                            >
                                <option value="">{translate('Select Time')}</option>
                                {
                                    state.hours.map((hour, index) => (
                                        <option key={index} value={hour.id}>{hour.title}</option>
                                    ))
                                }
                            </select>
                        </div> */}
                        <div className="col-lg-12 mb-3">
                            <label htmlFor="comments" className="form-label">{(translate('Comments'))}</label>
                            <textarea rows="3" className="form-control rounded-2" id="comments" name="comments"
                                value={state.userInformation.comments || ''}
                                onChange={handleInputChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className={`text-${i18n.language === 'ar' ? 'start' : 'end'} d-lg-block d-none`}>
                        <button type="submit" form="order-form" className="btn btn-success fw-bold px-4 py-2" disabled={loading}>
                            {
                                loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            }
                            <i className={`fab fa-whatsapp m${i18n.language === 'ar' ? 's' : 'e'}-2`}></i>
                            {translate('Complete order on whatsapp')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserInformation