import React, { useReducer, useEffect } from 'react'
import { Footer, AboutSection, Navbar } from '../Shared/components'
import reducer, { initialState } from '../Shared/reducer';
import { GET_CART_ITEMS } from '../Shared/actions';
import ShoppingCartProvider from '../Shared/shopping-cart-context'

const About = ({ translate, i18n }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: GET_CART_ITEMS, payload: dispatch })
  }, [])

  return (
    <ShoppingCartProvider state={state} dispatch={dispatch}>
      <div className="container-xxl position-relative p-0">
        <Navbar translate={translate} i18n={i18n} dispatch={dispatch} />
        <div class="container-xxl py-5 bg-dark hero-header mb-5">
          <div class="container text-center my-5 pt-5 pb-4">
            <h1 class="display-3 text-white mb-3 animated slideInDown">{translate('About Us')}</h1>
          </div>
        </div>
      </div>
      <AboutSection translate={translate} />
      <Footer translate={translate} i18n={i18n} />
    </ShoppingCartProvider>
  )
}

export default About