import {
    GET_CATEGORIES,
    CART_ACTIONS,
    GET_ITEM,
    GET_CART_ITEMS,
    UPDATE_USER_INFO,
    GET_TIME_SLOTS,
    SUBMIT_SUCCESS,
    SUBMIT_ERROR
} from "./actions";
import { asyncMiddleware } from "./asyncMiddleware";

export const initialState = {
    userInformation: {
        phoneNumber: '',
        comments: '',
        timeSlots: ''
    },
    categories: [],
    hours: [],
    cart: [],
    order: [],
    item: {},
    cartIsEmpty: false,
    cartTotalPrice: 0,
}

const reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CATEGORIES:
            asyncMiddleware(type, payload);
            return {
                ...state,
                ...payload
            }
        case GET_TIME_SLOTS:
            asyncMiddleware(type, payload);
            return {
                ...state,
                ...payload
            }
        case GET_ITEM:
            return {
                ...state,
                item: payload,
            };
        case CART_ACTIONS:
            asyncMiddleware(
                payload.type,
                payload.dispatch,
                { cartItem: payload.cartItem, itemQty: payload.quantity, selectedVariant: payload.selectedVariant, selectedExtras: payload.selectedExtras }
            )
            return {
                ...state,
                ...payload
            };
        case GET_CART_ITEMS:
            state.cart = [];
            asyncMiddleware(type, payload)
            return {
                ...state,
                ...payload
            };
        case UPDATE_USER_INFO:
            return {
                ...state,
                userInformation: {
                    ...state.userInformation,
                    ...payload,
                },
            };
        case SUBMIT_SUCCESS:
            asyncMiddleware(type, payload)
            return {
                ...state,
                ...payload
            }
        case SUBMIT_ERROR:
            return {
                ...state
            };
        default:
            return state;
    }
}

export default reducer;