import React from 'react'
import { CURRENCY } from '../../../Shared/global';
import { useShoppingCart } from '../../../Shared/shopping-cart-context'

const Payment = ({ translate, i18n }) => {
    const { cartTotalPrice } = useShoppingCart();
    return (
        <>
            <div className="card p-lg-2 mt-4 rounded-3">
                <div className="card-body">
                    <h5 className="card-title">{translate('Payment Details')}</h5>
                    <div className="my-3 form-check d-flex align-items-center justify-content-between p-0">
                        <label className="form-check-label d-flex align-items-center" htmlFor="cash">
                            <i className="fa-solid fa-money-bills fa-lg"></i>
                            <p className="mb-0">{translate('Cash on delivery')}</p>
                        </label>
                        <div className="checkArea">
                            <input className={`form-check-input radio ${i18n.language === 'ar' ? 'm-0' : ''}`} type="radio" name="payment_method" id="cash" checked={true} readOnly />
                        </div>
                    </div>
                    <div className="total mt-4">
                        <div className="pb-2 mb-2 border border-bottom-2 border-top-0 border-start-0 border-end-0">
                            <div className="d-flex justify-content-between align-items-center">
                                <p>{translate('Subtotal')}</p>
                                <p><span className="total-unit-price">{cartTotalPrice.toLocaleString()} </span>{translate(CURRENCY)}</p>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center">
                            <p>{language["delivery service fee"]}</p>
                            <p><span>{state.cartData.shipping_price} </span>{translate(CURRENCY)}</p>
                        </div> */}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="fw-bold">{translate('Total')}</p>
                            <p className="fw-bold"><span>{(cartTotalPrice).toLocaleString()} </span>{translate(CURRENCY)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Payment