import React, {
    createContext,
    useState,
    useContext,
    useEffect
} from "react";
import {
    ADD_TO_CART,
    CART_ACTIONS,
    DELETE_ITEM_CART,
    UPDATE_ITEM_CART,
} from "./actions";

import {
    toast
} from '../Shared/components';

const ShoppingCartContext = createContext({});

const ShoppingCartProvider = ({ children, state, dispatch }) => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const initialCartItems = Object.keys(state.cart).length > 0
            ? state.cart
            : [];
        setCartItems(initialCartItems)
    }, [state.cart])

    const addToCart = (cartItem, quantity, selectedVariant, selectedExtras) => {
        const { id, name, logom, price } = cartItem
        setCartItems((items) => {
            if (
                items.find((item) => item.id === id) === undefined
                || items.find((item) => item.id === id) === null
                || items.find((item) => item.selectedVariant !== selectedVariant)
            ) {
                dispatch({
                    type: CART_ACTIONS,
                    payload: {
                        type: ADD_TO_CART,
                        dispatch,
                        cartItem,
                        quantity,
                        selectedVariant,
                        selectedExtras
                    }
                })
                return [
                    ...items,
                    {
                        id,
                        name,
                        logom,
                        price,
                        quantity,
                        selectedVariant,
                        selectedExtras
                    }
                ]
            } else {
                return items.map((item) => {
                    if (item.id === id) {
                        dispatch({
                            type: CART_ACTIONS,
                            payload: {
                                type: ADD_TO_CART,
                                dispatch,
                                cartItem,
                                quantity
                            }
                        })
                        return {
                            ...item,
                            quantity: item.quantity + quantity
                        }
                    } else {
                        return item;
                    }
                })
            }
        })
        toast.success("Item added to cart successfully!");
    }

    const cartQuantity = cartItems.reduce((totalQuantity, item) => totalQuantity += item.quantity, 0);

    // const cartTotalPrice = cartItems.reduce((totalPrice, item) => totalPrice += (item.price * item.quantity), 0);
    let cartTotalPrice = 0;
    cartItems.forEach((item) => {
        if (item.selectedVariant && item.selectedVariant.price) {
            cartTotalPrice += (item.selectedVariant.price * item.quantity)
        } else if (item.selectedExtras && item.selectedExtras.length > 0) {
            cartTotalPrice += (item.selectedVariant.price * item.quantity) + item.selectedExtras.reduce((total, extra) => total += extra.price, 0)
        } else {
            cartTotalPrice += (item.price * item.quantity)
        }
    })
    state.cartTotalPrice = cartTotalPrice;

    const getItemQuantity = (id) => {
        let item = cartItems.find((item) => item.id === id)
        let qty = item ? item.quantity : 1
        return qty;
    }

    const increaseCartQuantity = (id) => {
        setCartItems((items) => {
            if (items.find((item) => item.id === id) !== undefined || items.find((item) => item.id === id) !== null) {
                return items.map((item) => {
                    if (item.id === id) {
                        dispatch({
                            type: CART_ACTIONS,
                            payload: {
                                type: ADD_TO_CART,
                                dispatch,
                                cartItem: item,
                                quantity: item.quantity + 1
                            }
                        })
                        return {
                            ...item,
                            quantity: item.quantity + 1
                        }
                    } else {
                        return item;
                    }
                })
            }
        })
        toast.success("Cart item updated successfully!");
    }

    const decreaseCartQuantity = (id) => {
        setCartItems((items) => {
            if (items.find((item) => item.id === id) === undefined || items.find((item) => item.id === id) === null) {
                return items.filter((item) => item.id !== id)
            } else {
                return items.map((item) => {
                    if (item.id === id) {
                        dispatch({
                            type: CART_ACTIONS,
                            payload: {
                                type: UPDATE_ITEM_CART,
                                dispatch,
                                cartItem: item,
                                quantity: item.quantity - 1
                            }
                        })
                        return {
                            ...item,
                            quantity: item.quantity - 1
                        }
                    } else {
                        return item;
                    }
                })
            }
        })
        toast.success("Cart item updated successfully!");
    }

    const removeItem = (id) => {
        setCartItems((items) => {
            return items.filter((item) => item.id !== id)
        })

        cartItems.map((item) => {
            if (item.id === id) {
                dispatch({
                    type: CART_ACTIONS,
                    payload: {
                        type: DELETE_ITEM_CART,
                        dispatch,
                        cartItem: item,
                    }
                })
            }
        });
        toast.success("Cart item deleted successfully!");
        checkEmptyCart();
    }

    const checkEmptyCart = () => {
        if (cartQuantity <= 0) {
            state.cartIsEmpty = true
        }
    }

    return (
        <ShoppingCartContext.Provider value={{
            cartItems,
            cartQuantity,
            cartTotalPrice,
            addToCart,
            getItemQuantity,
            increaseCartQuantity,
            decreaseCartQuantity,
            removeItem,
        }}>
            {children}
        </ShoppingCartContext.Provider>
    )
}

export default ShoppingCartProvider;

export const useShoppingCart = () => {
    return useContext(ShoppingCartContext)
}