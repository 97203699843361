import React, { useReducer, useEffect } from 'react'
import { Footer, Navbar } from '../Shared/components'
import reducer, { initialState } from '../Shared/reducer';
import { GET_CART_ITEMS } from '../Shared/actions';
import ShoppingCartProvider from '../Shared/shopping-cart-context'
import { VENDOR_PHONE } from '../Shared/global';
const Contact = ({ translate, i18n }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    dispatch({ type: GET_CART_ITEMS, payload: dispatch })
  }, [])
  return (
    <ShoppingCartProvider state={state} dispatch={dispatch}>
      <div className="container-xxl position-relative p-0">
        <Navbar translate={translate} i18n={i18n} dispatch={dispatch} />
        <div className="container-xxl py-5 bg-dark hero-header mb-5">
          <div className="container text-center my-5 pt-5 pb-4">
            <h1 className="display-3 text-white mb-3 animated slideInDown">{translate('Contact Us')}</h1>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h5 className="section-title ff-secondary text-center text-primary fw-normal">{translate('Contact Us')}</h5>
            <h1 className="mb-5">{translate('Contact For Any Query')}</h1>
          </div>
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                <div className="col-md-4">
                  <h5 className={`section-title ff-secondary fw-normal text-${i18n.language === 'ar' ? 'end' : 'start'} text-primary`}>{translate('Booking')}</h5>
                  <p><i className={`fa fa-phone-alt text-primary m${i18n.language === 'ar' ? 's' : 'e'}-2`}></i>{VENDOR_PHONE}</p>
                </div>
                <div className="col-md-4">
                  <h5 className={`section-title ff-secondary fw-normal ${i18n.language === 'ar' ? 'end' : 'start'} text-primary`}>{translate('Location')}</h5>
                  <p><i className={`fa fa-location-arrow text-primary m${i18n.language === 'ar' ? 's' : 'e'}-2`}></i>شارع كورنيش القواسم - السوق القديم - إمارة رأس الخيمة - الإمارات العربية المتحده</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
              <iframe title='map' className="position-relative rounded w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.129227881575!2d55.94752707454151!3d25.79931040705163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef6714cb283048d%3A0xeb436dade37f1b73!2sOriginal%20Taste%20RAK!5e0!3m2!1sen!2seg!4v1703198929737!5m2!1sen!2seg"
                frameborder="0" style={{ minHeight: '350px', border: 0 }} allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
            </div>
            <div className="col-md-6">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                <form>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="name" placeholder={translate('Your Name')} />
                        <label for="name">{translate('Your Name')}</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="email" placeholder={translate('Your Email')} />
                        <label for="email">{translate('Your Email')}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="subject" placeholder={translate('Subject')} />
                        <label for="subject">{translate('Subject')}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" placeholder={translate('Leave a message here')} id="message" style={{ height: '150px' }}></textarea>
                        <label for="message">{translate('Message')}</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">{translate('Send Message')}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer translate={translate} i18n={i18n} />
    </ShoppingCartProvider>
  )
}

export default Contact