import React from 'react'
import { GET_CATEGORIES } from '../actions';
import { VENDOR_PHONE } from '../global';

function TopBar({ i18n, dispatch }) {
    const handleChangeLanguage = (language) => {
        i18n.changeLanguage(language);
        document.documentElement.lang = language;
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
        dispatch({ type: GET_CATEGORIES, payload: dispatch })
    }

    return (
        <div className='top-bar bg-dark py-3 text-white'>
            <div className="row gx-0 justify-content-center align-items-center">
                <div className={`col-lg-7 col-md-6 px-md-3 px-1 text-md-${i18n.language === 'ar' ? 'end' : 'start'} text-center`}>
                    <ul className='list-inline d-flex justify-content-center justify-content-md-start align-items-center mb-0 p-0'>
                        <li>
                            <span>
                                <small className={`far fa-clock text-primary m${i18n.language === 'ar' ? 's' : 'e'}-2`}></small>
                                <small>12:00 PM - 12:00 AM</small>
                            </span>
                        </li>
                        <li>
                            <span className='mx-3'>|</span>
                            <span>
                                <small className={`fa fa-phone-alt text-primary m${i18n.language === 'ar' ? 's' : 'e'}-1`}></small>
                                <small> <a href={`tel:${VENDOR_PHONE}`}>{VENDOR_PHONE}</a></small>
                            </span>
                        </li>
                    </ul>
                </div>
                <div className={`col-lg-5 col-md-6 px-md-3 px-1 text-md-${i18n.language === 'ar' ? 'start' : 'end'} text-center`}>
                    {
                        i18n.language === 'en' &&
                        <a
                            role="button"
                            className="font-weight-bold cursor-pointer"
                            onClick={() => handleChangeLanguage('ar')}
                        >
                            العربية
                        </a>
                    }
                    {
                        i18n.language === 'ar' &&
                        <a
                            role="button"
                            className="font-weight-bold cursor-pointer"
                            onClick={() => handleChangeLanguage('en')}
                        >
                            English
                        </a>
                    }
                </div>
            </div>
        </div>
    )
}

export default TopBar