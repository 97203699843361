import "../../../node_modules/swiper/swiper-bundle.min.css";
import "../../../node_modules/swiper/swiper-bundle.min.js";
import React, { useEffect, useReducer } from "react";
import reducer, { initialState } from "../../Shared/reducer.js";
import { Link } from 'react-router-dom'

import {
  Navbar,
  AboutSection,
  Footer,
  AddItem
} from '../../Shared/components/index.js';
import ShoppingCartProvider from '../../Shared/shopping-cart-context.js'
import { GET_CART_ITEMS, GET_CATEGORIES } from "../../Shared/actions.js";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Navigation } from 'swiper/modules';
import HomeMenu from "./HomeMenu.jsx";

const Home = ({ translate, i18n }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: GET_CATEGORIES, payload: dispatch })
    dispatch({ type: GET_CART_ITEMS, payload: dispatch })
    const clearCacheData = () => {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    };
    clearCacheData();
  }, []);


  return (
    <>
      <ShoppingCartProvider state={state} dispatch={dispatch}>
        <div className="container-xxl position-relative p-0">
          <Navbar translate={translate} i18n={i18n} dispatch={dispatch}/>
          <div className="container-xxl p-0">

            <Swiper
              centeredSlides={true}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              effect={"fade"}
              fadeEffect={{
                crossFade: true
              }}
              speed={1000}
              navigation={true}
              modules={[EffectFade, Autoplay, Navigation]}
              className="home-slider"
              style={{ height: "800px" }}
            >
              <SwiperSlide>
                <img src={process.env.PUBLIC_URL + "/img/bg-hero-1.jpg"} alt="" />
                <div className="container mt-4 h-100">
                  <div className="row justify-content-center align-items-center g-5 h-100 text-center">
                    <div className="col-lg-6 text-center">
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Enjoy Our')}</h1><br/>
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Delicious Meal')}</h1>
                      <p className="text-white animated slideInUp mb-4 pb-2 bg-main-op">{translate('Indulge in the exquisite flavors of our carefully crafted meals. Immerse yourself in a culinary journey where each bite is a symphony of taste and texture')}</p>
                      <Link to="/menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInUp rounded-3">{translate('Explore Our Menu')}</Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={process.env.PUBLIC_URL + "/img/bg-hero-2.jpg"} alt="" />
                <div className="container mt-4 h-100">
                  <div className="row justify-content-center align-items-center g-5 h-100 text-center">
                    <div className="col-lg-6 text-center">
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Discover Amazing')}</h1><br/>
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Flavors')}</h1>
                      <p className="text-white animated slideInUp mb-4 pb-2 bg-main-op">{translate('Embark on a culinary adventure where each dish tells a unique story. Our chefs craft experiences that delight the senses and elevate your dining journey.')}</p>
                      <Link to="/menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInUp rounded-3">{translate('Explore Our Menu')}</Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <img src={process.env.PUBLIC_URL + "/img/bg-hero-3.jpg"} alt="" />
                <div className="container mt-4 h-100">
                  <div className="row justify-content-center align-items-center g-5 h-100 text-center">
                    <div className="col-lg-6 text-center">
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Enjoy Our')}</h1><br/>
                      <h1 className="display-3 text-white animated slideInUp bg-main-op">{translate('Delicious Meal')}</h1>
                      <p className="text-white animated slideInUp mb-4 pb-2 bg-main-op">{translate('Indulge in the exquisite flavors of our carefully crafted meals. Immerse yourself in a culinary journey where each bite is a symphony of taste and texture')}</p>
                      <Link to="/menu" className="btn btn-primary py-sm-3 px-sm-5 me-3 animated slideInUp rounded-3">{translate('Explore Our Menu')}</Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <AboutSection translate={translate} />
        <HomeMenu state={state} dispatch={dispatch} translate={translate} i18n={i18n} />
        <AddItem item={state.item} translate={translate} />
        <Footer translate={translate} i18n={i18n} />
      </ShoppingCartProvider>
    </>
  )
}

export default Home