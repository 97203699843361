import React, { useEffect, useState } from 'react'
import { useShoppingCart } from '../../../Shared/shopping-cart-context';
import { APP_URL, CURRENCY } from '../../../Shared/global';

const Item = ({ item, translate }) => {
    const { increaseCartQuantity, decreaseCartQuantity, getItemQuantity, removeItem } = useShoppingCart();
    const quantity = getItemQuantity(item.id);
    const [qty, setQty] = useState(quantity);
    const [itemPrice, setItemPrice] = useState(item.price);

    useEffect(() => {
        if (item.selectedExtras && item.selectedExtras.length > 0) {
            const totalPrice = item.selectedVariant.price + item.selectedExtras.reduce((sum, extra) => sum + extra.price, 0);
            setItemPrice(totalPrice);
        } else if (item.selectedVariant && item.selectedVariant.price) {
            setItemPrice(item.selectedVariant.price);
        } else {
            setItemPrice(item.price);
        }
    }, [qty, item.price, item.selectedExtras, item.selectedVariant]);

    let variantName = null;
    if (item.selectedVariant.options) {
        const options = JSON.parse(item.selectedVariant.options);
        variantName = options[Object.keys(options)[0]];
    }

    return (
        <div className="item pb-3 mb-3 border border-bottom-2 border-top-0 border-start-0 border-end-0">
            <div className="d-flex align-items-start">
                <img className="mx-0 border-2" src={APP_URL + item.logom} alt="item" width="90"
                    style={{
                        height: "80px",
                        objectFit: "cover"
                    }} />
                <div className="text mx-2">
                    <h6 className="item_name fw-bold">{item.name}</h6>
                    {
                        variantName ? <p className='mb-0'>{variantName}</p> : null
                    }
                    {
                        item.selectedExtras && item.selectedExtras.length > 0 ?
                            item.selectedExtras.map(extra => <span className="badge bg-primary mx-1" key={extra.id}>{extra.name}</span>) : null
                    }
                </div>
            </div>
            <div className="info mt-3">
                <div className="total d-flex justify-content-between align-items-center">
                    <div className="quantity-input border-0">
                        <button type="button" className="btn btn-main quantity-btn"
                            onClick={() => {
                                increaseCartQuantity(item.id)
                                setQty(qty + 1)
                            }}>
                            <i className="fa fa-plus"></i>
                        </button>
                        <span className="mx-3">{qty}</span>
                        <button type="button" className="btn btn-main quantity-btn" disabled={qty <= 1 ? true : false}
                            onClick={() => {
                                decreaseCartQuantity(item.id)
                                setQty(qty - 1)
                            }}>
                            <i className="fa fa-minus"></i>
                        </button>
                        <button type="button" className="btn btn-danger quantity-btn subtract-qty mx-2 rounded-3"
                            onClick={() => {
                                removeItem(item.id)
                            }}>
                            <i className="fa fa-trash-alt"></i>
                        </button>
                    </div>
                    <div className="total-price">
                        <strong className="text-primary fs-5">
                            {(itemPrice * qty).toLocaleString()} {translate(CURRENCY)}
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item