import React from 'react'
import { GET_ITEM } from '../actions';
import { APP_URL, CURRENCY } from '../global';

const Menu = ({ state, dispatch, translate }) => {
  const categoriesWithItems = state.categories.filter(category => category.items.length > 0);
  return (
    <div id="menu" className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">{translate('Food Menu')}</h5>
          <h1 className="mb-5">{translate('Most Popular Items')}</h1>
        </div>
        <div className="tab-className text-center wow fadeInUp" data-wow-delay="0.1s">
          <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
            {
              categoriesWithItems.map((category, index) => (
                <li className="nav-item" key={index}>
                  <a className={`d-flex align-items-center text-start mx-3 ms-0 pb-3 ${index === 0 ? "active" : ""}`} data-bs-toggle="pill" href={`#tab-${category.id}`}>
                    <div className="ps-3">
                      <h6 className="mt-n1 mb-0">{category.name}</h6>
                    </div>
                  </a>
                </li>

              ))
            }
          </ul>
          <div className="tab-content menu" id="category-items">
            {
              categoriesWithItems.map((category, index) => (
                <div id={`tab-${category.id}`} className={`tab-pane fade show p-0 ${index === 0 ? "active" : ""}`} key={index}>
                  <div className="row g-4">
                    {
                      category.items.map((item, index) => (
                        <div className="col-lg-4 col-6" key={index}>
                          <a href="/#" data-bs-toggle="modal" data-bs-target="#add-item"
                            onClick={() => {
                              dispatch({ type: GET_ITEM, payload: item });
                            }}
                          >
                            <div className="card border-0 overflow-hidden">
                              <div className="imag overflow-hidden">
                                <img src={APP_URL + item.logom} className="card-img-top" alt="..." />
                              </div>
                              <div className="card-body">
                                <h5 className="card-title">{item.name}</h5>
                                <small className="card-text d-block text-dark mb-2">{item.short_description}</small>
                                <strong className="text-primary">{item.price} {translate(CURRENCY)}</strong>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu