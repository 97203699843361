import {
    GET_CATEGORIES,
    ADD_TO_CART,
    UPDATE_ITEM_CART,
    DELETE_ITEM_CART,
    GET_CART_ITEMS,
    GET_TIME_SLOTS,
    SUBMIT_SUCCESS
} from "./actions";
import { categoriesApi, hoursApi } from "./axios-config";
import { toast } from "./components/Toaster";

export const asyncMiddleware = async (type, dispatch, options = {}) => {
    const { cartItem, itemQty, selectedVariant, selectedExtras } = options;
    try {
        let categories,
            hours,
            cart,
            order,
            cartIsEmpty = true;
        if (typeof type === 'string' && typeof dispatch === 'function') {
            switch (type) {
                case GET_CATEGORIES:
                    categories = await getCategories();
                    return dispatch({
                        type,
                        payload: { categories }
                    });
                case GET_TIME_SLOTS:
                    hours = await getHours();
                    return dispatch({
                        type,
                        payload: { hours }
                    })
                case GET_CART_ITEMS:
                    cart = await getCartItems();
                    if (cart.length > 0) {
                        cartIsEmpty = false
                    }
                    return dispatch({
                        type,
                        payload: {
                            cart,
                            cartIsEmpty
                        }
                    });
                case ADD_TO_CART:
                    await addItemToCart(cartItem, itemQty, selectedVariant, selectedExtras);
                    return dispatch({
                        type,
                        payload: {
                            dispatch,
                        }
                    });
                case UPDATE_ITEM_CART:
                    await updateCartItem(cartItem.id, itemQty);
                    return dispatch({
                        type,
                        payload: {
                            dispatch,
                        }
                    });
                case DELETE_ITEM_CART:
                    await deleteCartItem(cartItem.id);
                    return dispatch({
                        type,
                        payload: {
                            dispatch,
                        }
                    });
                case SUBMIT_SUCCESS:
                    order = await getCartItems();
                    setOrderItems(order);
                    await clearCart();
                    return dispatch({
                        type,
                        payload: {
                            order,
                            dispatch
                        }
                    })
            }
        }
    } catch (error) {
        throw error;
    }
}


export const getCategories = async () => {
    const lang = localStorage.getItem("i18nextLang") || "en" 
    try {
        const { data } = await categoriesApi.get('/categories?lang=' + lang)
        return data.data;
    } catch (error) {
        throw error;
    }
}

export const getHours = async () => {
    try {
        const { data } = await hoursApi.get('/hours')
        return data.data.timeSlots;
    } catch (error) {
        throw error;
    }
}

const toasterMessage = (status, value) => {
    if (status) {
        toast.success(value);
    } else {
        toast.error('error');
    }
}

export const getCartItems = async () => {
    try {
        const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
        return cartItems;
    } catch (error) {
        console.error('Error retrieving cart items:', error);
        return [];
    }
}

export const addItemToCart = async (cartItem, itemQty, selectedVariant, selectedExtras) => {
    try {
        const cartItems = await getCartItems();
        const existingItemIndex = cartItems.findIndex(item => item.id === cartItem.id);
        if (existingItemIndex !== -1) {
            cartItems[existingItemIndex].quantity = itemQty;
        } else {
            const newItem = { ...cartItem, quantity: itemQty, selectedVariant, selectedExtras };
            cartItems.push(newItem);
        }

        localStorage.setItem('cart', JSON.stringify(cartItems));
        // toasterMessage(true, 'Item added to cart successfully!');
    } catch (error) {
        console.error('Error adding item to cart:', error);
    }
}

export const updateCartItem = async (cartItemId, itemQty) => {
    try {
        const cartItems = await getCartItems();
        const itemIndex = cartItems.findIndex(item => item.id === cartItemId);

        if (itemIndex !== -1) {
            cartItems[itemIndex].quantity = itemQty;
            localStorage.setItem('cart', JSON.stringify(cartItems));
        }
        // toasterMessage(true, 'Cart item updated successfully!');
    } catch (error) {
        console.error('Error updating cart item:', error);
    }
}

export const deleteCartItem = async (itemId) => {
    try {
        const cartItems = await getCartItems();
        const updatedCart = cartItems.filter(item => item.id !== itemId);

        localStorage.setItem('cart', JSON.stringify(updatedCart));
        // toasterMessage(true, 'Cart item deleted successfully!');
    } catch (error) {
        console.error('Error deleting cart item:', error);
    }
}

export const clearCart = async () => {
    try {
        localStorage.removeItem('cart');
    } catch (error) {
        console.error('Error clearing cart:', error);
    }
}

export const setOrderItems = (order) => {
    if (order) {
        localStorage.setItem('order', JSON.stringify(order));
    }
}